:root {
  --primary-color: #95c020;
  --white-color: #ffffff;
  --secondary-color: #ffffff;
  --black-color: #000000;
  --primary-light: #dcf1a1;
  --placeholder: #939393;
  --devider-color: #ebebeb;
  --border-color: #ebebeb;
  --disable-color-opacity-02: rgba(235, 235, 235, 0.2);
  --disable-color-opacity-1: rgba(235, 235, 235, 1);
  --disable-color: #cccccc;
  --schedule-wo-awaiting-customer-color: #fd7e14;
  --primary-opacity2: rgba(149, 192, 32, 0.2);
  --orange-yellow-opacity2: rgba(240, 204, 28, 0.2);
  --dark-orange-yellow: rgb(255, 140, 0);
  --dark-green-opacity2: rgba(57, 91, 80, 0.2);
  --light-red: rgba(197, 64, 54, 0.3);
  --dark-red: rgb(197, 64, 54);
  --light-grey: rgba(249, 249, 249, 1);
  --dark-grey: rgba(0, 0, 0, 0.23);
  --font-grey-color: rgba(30, 30, 30, 0.5);
  --radio-grey: rgba(118, 118, 118, 1);
  --text-grey: rgba(0, 0, 0, 0.6);
  --box-gray: rgba(235, 235, 235, 0.4);

  /* Status Color Start */
  --in-review-color: #59c3c3;
  /* Status Color End */

  /* Import data icon color */
  --import-data-loader-color: rgba(18, 18, 18, 1);
  --state-colors-red: rgba(197, 64, 54, 1);
}

html,
body {
  width: 100%;
  height: 100%;
  background-color: var(--devider-color) !important;
}

/* Rsuite Date range picker */
.rs-input-group-md.rs-input-group > .rs-input {
  height: 35px;
}
.rs-picker-caret-icon {
  display: none;
}
.rs-input-group:focus-within {
  outline: none !important;
}
.rs-input-group:focus-visible {
  outline: none !important;
}

.rs-picker.rs-picker-focused,
.rs-picker:not(.rs-picker-disabled):hover {
  border-color: initial;
}
.rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
  border-color: var(--primary-color);
  outline: none;
}
.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled):hover {
  border-color: var(--primary-color);
}
.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled):focus {
  border-color: var(--primary-color);
}
.rs-input-group.rs-input-group-disabled,
.rs-input-group.rs-input-group-disabled > input {
  background-color: var(--disable-color-opacity-1) !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  box-shadow: inset 0 0 0 1px var(--primary-color);
}
.rs-btn-primary,
.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  background-color: var(--primary-color);
}
.rs-calendar-table-cell-in-range:before,
.rs-calendar-table-cell-content:hover {
  background-color: var(--primary-light);
}
.rs-btn-primary.rs-btn-disabled,
.rs-btn-primary:disabled {
  background-color: var(--primary-color);
  opacity: 0.3;
}

.rs-btn-primary:hover {
  background-color: var(--primary-color);
  color: var(--white-color);
}
.rs-picker-popup
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: var(--primary-light);
  color: var(--black-color);
}
.rs-input-group.rs-input-group-inside {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rs-input-group-md > .rs-input {
  font-size: 14px;
  font-weight: 400;
}
.rs-input,
.rs-date-range-input {
  color: var(--black-color);
  opacity: 0.38;
}
.rs-picker-has-value .rs-input {
  opacity: 1;
}

.rs-input-group-addon {
  font-size: 16px !important;
}
/* Picker Popup */
.rs-picker-popup.rs-picker-popup-daterange {
  z-index: 9999;
}
.rs-anim-fade {
  z-index: 9999;
}
.rs-stack-item > button {
  color: var(--primary-color);
}
.rs-stack-item > button:hover {
  color: var(--primary-color);
  text-decoration: none;
  background-color: var(--disable-color);
}

/* Primary Button Styles */
.rs-btn-primary {
  background-color: var(--primary-color);
}

.rs-btn-primary.rs-btn-active,
.rs-btn-primary:active,
.rs-btn-primary:focus,
.rs-btn-primary:hover {
  background-color: var(--primary-color) !important;
  color: var(--white-color);
}

/* Calendar Time Dropdown Cell */
.rs-calendar-time-dropdown-cell {
  &.rs-calendar-time-dropdown-cell-active {
    background-color: var(--primary-color);
  }

  &:hover {
    background-color: var(--primary-light);
    color: var(--black-color);
  }
}

/* Calendar Header Titles */
.rs-calendar-time-view .rs-calendar-header-title-time,
.rs-calendar-month-view .rs-calendar-header-title-date,
.rs-calendar-month-dropdown-year-active {
  color: var(--primary-color);
}

/* Active Month Dropdown Cell */
.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content {
  background-color: var(--primary-color);
  color: var(--white-color);
}

/* Hover Effects for Month Dropdown Cells */
.rs-picker-popup .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
  background-color: var(--primary-light);
  color: var(--black-color);
}

/* Carousel View Css  */
.carousel-root {
  width: 100%;
}
.carousel .thumbs {
  display: flex;
  justify-content: center;
}
.carousel .thumb.selected {
  border: none !important;
}

.thumbs .thumb {
  height: 80px;
  width: 80px;
  border: none !important;
}

.thumbs .thumb > img {
  height: inherit !important;
  width: inherit !important;
}

.thumbs .thumb.selected {
  height: 100px !important;
  width: 100px !important;
}

.rs-picker-date .rs-input {
  opacity: 0.7;
}

/* Chart */

.custom-pie-chart {
  flex: 1.4 !important;
}

/* CKeditor */
.ck-content {
  height: 300px;
  font-weight: 400;
}

.ck-balloon-panel {
  z-index: 1300 !important;
}
