.rbc-today {
  background-color: transparent !important;
}

.rbc-events-container {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.rbc-event,
.rbc-background-event {
  color: rgba(30, 30, 30, 1);
  border: 0px !important;
  padding: 0 !important;
  align-items: center !important;

  &.isBookingRequired {
    background: rgba(253, 126, 20, 0.2) !important;
    border-left: 4px solid var(--schedule-wo-awaiting-customer-color) !important;
  }

  &.noBookingRequired {
    background: rgba(149, 192, 32, 0.2) !important;
    border-left: 4px solid var(--primary-color) !important;
  }

  &.leaveApproved {
    background: var(--light-red) !important;
    border-left: 4px solid var(--dark-red) !important;
  }

  &.leaveDraft {
    background: var(--orange-yellow-opacity2) !important;
    border-left: 4px solid var(--dark-orange-yellow) !important;
  }

  &.timeSheet {
    background: rgba(149, 192, 32, 0.2) !important;
  }

  .rbc-event-content {
    padding: 3px 0 0 8px !important;
    height: auto !important;
    align-content: start;
  }
}

.rbc-show-more {
  color: var(--primary-color) !important;
}

.rbc-row-segment {
  display: flex !important;
  justify-content: center !important;
}

.rbc-event:focus,
.rbc-day-slot .rbc-background-event:focus {
  outline: none !important;
  box-shadow: none !important;
}

.rbc-timeslot-group {
  min-height: 50px !important;
  border-bottom: 1px solid var(--border-color) !important;
}

.rbc-time-header-content {
  .rbc-row {
    &.rbc-row-resource {
      height: 26px !important;
      align-items: center !important;

      .rbc-header {
        border-bottom: 0 !important;
        padding: 0 !important;
        height: 100%;

        /* Apply these styles to both .odd-resource and .resource-green */
        .odd-resource,
        .even-resource {
          width: 100% !important;
          height: 100%;
          display: flow-root;
          justify-items: center;
          align-content: center;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        /* Unique background color for odd-resource */
        .odd-resource {
          background-color: var(
            --border-color
          ); /* Adjust color variable if needed */
        }
      }
    }
  }
}

.rbc-event-label {
  display: none;
}

.rbc-event.rbc-selected,
.rbc-day-slot .rbc-selected.rbc-background-event {
  background-color: rgba(149, 192, 32, 0.2);
}

.rs-picker-error > .rs-picker-input-group,
.rs-picker-error > .rs-picker-input-group:hover {
  border: 1px solid #cccccc !important;
}

.rs-input:disabled {
  background-color: var(--disable-color) !important;
}

.rs-input-group.rs-input-group-disabled {
  background-color: var(--disable-color) !important;
  color: rgba(0, 0, 0, 0.38) !important;
}

.rs-input-group:focus-within,
.rs-input-group:not(.rs-input-group-disabled):hover {
  border: 1px solid var(--primary-color) !important;
}

// Timesheet start
.timeSheet {
  .rbc-time-view {
    border: 0 !important;
  }

  .rbc-month-view {
    border: 0 !important;
  }

  .rbc-show-more {
    color: var(--black-color) !important;
    font-weight: 400;
  }

  .today-date {
    position: relative;
  }

  .rbc-date-cell {
    padding-left: 5px !important;
    text-align: left !important;
    padding-bottom: 5px !important;

    &.rbc-now.rbc-current {
      padding-top: 5px !important;

      & button {
        background-color: var(--primary-color);
        color: white;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
      }
    }
  }

  // .rbc-allday-cell {
  //   display: none;
  // }
  .rbc-time-view .rbc-header {
    border-bottom: none;
  }

  .rbc-row {
    padding-bottom: 5px !important;
  }

  .rbc-row-content {
    min-height: 110px !important;
  }

  .rbc-month-row {
    overflow: visible !important;
  }
  .rbc-time-content > * + * > * {
    border-left: 0px solid var(--border-color) !important;
  }
  .rbc-timeslot-group {
    border-left: 1px solid var(--border-color) !important;
  }

  .rbc-event,
  .rbc-background-event {
    &.draft {
      background-color: var(--orange-yellow-opacity2) !important;
    }

    &.submitted {
      background-color: var(--primary-opacity2) !important;
    }

    &.approved {
      background-color: var(--dark-green-opacity2) !important;
    }

    &.leave {
      background-color: var(--light-red) !important;
    }
  }
  .rbc-event-content {
    padding: 3px 0 0 8px !important;
    height: auto !important;
    align-content: start;
  }
}

.rbc-event.timeSheet,
.rbc-background-event.timeSheet {
  &.draft {
    background-color: var(--orange-yellow-opacity2) !important;
  }

  &.submitted {
    background-color: var(--primary-opacity2) !important;
  }

  &.approved {
    background-color: var(--dark-green-opacity2) !important;
  }

  &.leave {
    background-color: var(--light-red) !important;
  }
}

// Timesheet end

// Common start
.rbc-overlay {
  max-height: 500px;
  overflow-y: auto;
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  .rbc-overlay-header {
    position: sticky;
    top: -10px;
    background-color: white;
    z-index: 1;
    border-bottom: 1px solid var(--devider-color);
  }
}
// Common end
